<template>
  <div class="CcastWrapper">
    <div class="cast-metadata cast-metadata-player" v-if="title.length > 0">
      <span class="title-metadata">{{ title }}</span>
      <span class="time-metadata">
        <span>{{ subtitle }}</span>
      </span>
    </div>
    <div class="imageSub">
      <img
        v-if="poster"
        :src="poster"
        alt="poster contenido"
        class="castPoster"
        id="video_image"
      />
    </div>
  </div>
</template>
<script>
const TIMER_STEP = 1000;

const PLAYER_STATE = {
  IDLE: "IDLE",
  BUFFERING: "BUFFERING",
  LOADED: "LOADED",
  PLAYING: "PLAYING",
  PAUSED: "PAUSED",
  IS_MUTED_CHANGED: "MUTED",
  VOLUME_LEVEL_CHANGED: "VOLUME_LEVEL_CHANGED",
};

const CAST = {
  MESSAGE_NAMESPACE: "urn:x-cast:com.connectsdk",
  STATE_IDLE: "IDLE",
  STATE_CONNECTING: "CONNECTING",
  STATE_CONNECTED: "CONNECTED",
  PLAYER_BUFFERING: "BUFFERING",
  PLAYER_PLAYING: "PLAYING",
  PLAYER_PAUSED: "PAUSED",
  PLAYER_IDLE: "IDLE",
  DisconnectReason: {
    ERROR: "error",
    REQUESTED_BY_SENDER: "requested_by_sender",
    UNKNOWN: "unknown",
  },
  VIDEO_TYPE: {
    NPVR: "NPVR",
    LIVE: "LIVE",
    VOD: "VOD",
    CATCHUP: "CATCHUP",
  },
};

export default {
  name: "Ccast",
  props: {
    poster: {
      type: String,
      default: "",
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    subtitle: {
      type: String,
      default: "",
      required: false,
    },
    isCCast: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      remotePlayer: null,
      remotePlayerController: null,
      session: null,
      media: null,
      customData: null,
      asset: null,
      playerStateBeforeSwitch: null,
      playerState: null,
      progress: {},
      lastPositionms: null,
      intervals: {},
      mediaDuration: -1,
      mediaInfo: {},
      currentMediaTime: null,
      incrementMediaTimeHandler: null,
      isLiveContent: false,
      timer: null,
      liveSeekableRange: null,
      localPlayer: null,
      timeToLive: 15000, // tiempo para saber si cargó el asset o no
      assetLoaded: false,
    };
  },
  computed: {
    friendlyName() {
      let castSession = "";
      if (cast.framework.CastContext.getInstance().getCurrentSession()) {
        castSession = cast.framework.CastContext.getInstance()
          .getCurrentSession()
          .getCastDevice().friendlyName;
      }
      return castSession;
    },
  },
  mounted() {
    // Defino eventos
    this.playerState = PLAYER_STATE.IDLE;
    this.$bus.$on("cast-setevents", () => {
      this.setEvents();
    });
    this.$bus.$on("cast-removeevents", () => {
      this.removeEvents();
    });
    this.$bus.$emit("set-cast-title", this.title);
    this.$bus.$emit("set-cast-subtitle", this.subtitle);
    this.localPlayer = document.getElementsByTagName("video")[0];
  },
  methods: {
    switchPlayer() {
      if (cast && cast.framework && tplay.ccast.remotePlayer.isConnected) {
        this.localPlayer.addEventListener(
          "loadeddata",
          this.onMediaLoadedLocally
        );
        // this.$bus.$emit('cast:connected', true);
        // const tplayCasAuthToken = telecentro.tplay.core.localStorageFake.getItem('tplayCasAuthToken');
        // telecentro.tplay.core.api.casVirtualId(tplayCasAuthToken, () => console.error('casvirtual id error'), () => console.info('OK --> casVirtualID'));
        // this.load();
      } else {
        this.$bus.$emit(
          "cast:connected",
          false,
          tplay.ccast.estimatedRemoteMediaTime
        );
        this.$bus.$emit("cast-resetinterval");
      }
    },
    setEvents() {
      // this.$bus.$on('cast-initlisteners', () => { this.initListeners(); });
      this.$bus.$on("cast-load", (source) => {
        this.load(source)
          .then(() => console.info("asset cargado"))
          .catch((e) => console.error("error al cargar: ", e));
      });
      this.$bus.$on("cast-setposition", (time, ms) => {
        this.setPosition(time, ms);
      });
      this.$bus.$on("cast-resetinterval", () => {
        clearInterval(this.intervals.media);
      });
      this.$bus.$on("cast-playpause", () => {
        this.playPause();
      });
      this.$bus.$on("cast-seekTo", (time) => {
        this.seekMediaRemote(time);
      });
      this.$bus.$on("cast-muteorunmuted", () => {
        this.muteOrUnmuted();
      });
      this.$bus.$on("cast-setvolume", (vol) => {
        this.setVolume(vol);
      });
      this.$bus.$on("cast-settrack", (track, type) => {
        this.setTextTrack(track, type);
      });
      this.$bus.$on("cast-switchplayer", () => {
        this.switchPlayer();
      });
      this.$bus.$on("cast-mediachanged", () => {
        this.mediachanged();
      });
      this.$bus.$on("cast-liveseekchanged", (event) => {
        this.liveSeekableRange = event.value;
      });
    },
    removeEvents() {
      this.$bus.$off("cast-load", (source) => {
        this.load(source)
          .then(() => console.info("asset cargado"))
          .catch((e) => console.error("error al cargar: ", e));
      });
      this.$bus.$off("cast-setposition", (time, ms) => {
        this.setPosition(time, ms);
      });
      this.$bus.$off("cast-resetinterval", () => {
        clearInterval(this.intervals.media);
      });
      this.$bus.$off("cast-playpause", () => {
        this.playPause();
      });
      this.$bus.$off("cast-seekTo", (time) => {
        this.seekMediaRemote(time);
      });
      this.$bus.$off("cast-muteorunmuted", () => {
        this.muteOrUnmuted();
      });
      this.$bus.$off("cast-setvolume", (vol) => {
        this.setVolume(vol);
      });
      this.$bus.$off("cast-settrack", (track) => {
        this.setTextTrack(track);
      });
      this.$bus.$off("cast-mediachanged", () => {
        this.mediachanged();
      });
      this.$bus.$off("cast-liveseekchanged", (event) => {
        this.liveSeekableRange = event.value;
      });
      // this.$bus.$off('cast-initlisteners', () => { this.initListeners(); });
      // this.$bus.$off('cast-removelisteners', () => { this.removeListeners(); });
      this.$bus.$off("cast-switchplayer", () => {
        this.switchPlayer();
      });
    },
    mediachanged() {
      console.info("MEDIA_INFO_CHANGED");
      let session =
        cast.framework.CastContext.getInstance().getCurrentSession();
      if (!session) {
        this.mediaInfo = null;
        this.isLiveContent = false;
        return;
      }

      let media = session.getMediaSession();
      if (!media) {
        this.mediaInfo = null;
        this.isLiveContent = false;
        return;
      }

      this.mediaInfo = media.media;

      if (this.mediaInfo) {
        this.isLiveContent =
          this.mediaInfo.streamType == chrome.cast.media.StreamType.LIVE;
      } else {
        this.isLiveContent = false;
      }
      if (
        media.playerState == PLAYER_STATE.PLAYING &&
        this.playerState !== PLAYER_STATE.PLAYING
      ) {
        this.prepareToPlay();
      }
    },
    load(source = null) {
      return new Promise((resolve, reject) => {
        if (source === "START") {
          console.info("start correcto");
          resolve();
          return false;
        }
        if (!source) {
          source = this.currentMediaIndex;
        }
        let mediaSource = null;
        if (source) {
          mediaSource = source;
        } else {
          mediaSource = window.vip.player.getSource();
        }

        if (!mediaSource) {
          console.warn("aun no hay medios");
          return false;
        }
        this.playerState = PLAYER_STATE.BUFFERING;
        this.createMediaInfo(
          mediaSource.asset,
          this.createGenericMetadata(mediaSource.asset)
        ).then((mediaInfo) => {
          const timeToSeek =
            mediaSource.seekTime > 0 ? mediaSource.seekTime : 0;
          const timeLapsed = new Date(
            Math.trunc(this.progress.value * 1000)
          ).getTime();
          if (timeLapsed > 0) {
            this.bus.$emit("remote:seek-time", timeLapsed);
          }
          let session =
            cast.framework.CastContext.getInstance().getCurrentSession();
          this.session = session;

          mediaInfo.seek = timeToSeek;
          mediaInfo.customData.asset.seekTime = timeToSeek;

          let request = new chrome.cast.media.LoadRequest(mediaInfo);
          request.sessionId = session.sessionId;
          request.requestId = mediaSource.asset.idAsset + "";
          request.currentTime = timeToSeek;
          mediaInfo.metadata.sectionStartTimeInMedia = 0;

          if (
            !this.playerStateBeforeSwitch ||
            this.playerStateBeforeSwitch == PLAYER_STATE.PAUSED
          ) {
            request.autoplay = false;
          } else {
            request.autoplay = true;
          }
          setTimeout(() => {
            if (!this.assetLoaded) {
              this.$bus.$emit("cast-removelisteners");
              this.session.endSession();
              this.$bus.$emit(
                "show-toast",
                "error",
                `Error al cargar video en el dispositivo: ${this.friendlyName}`
              );
              this.$bus.$emit("cast:connected", false);
              this.$bus.$emit("player-cast-ready");
            }
          }, this.timeToLive);
          session.loadMedia(request).then(
            () => {
              this.session =
                cast.framework.CastContext.getInstance().getCurrentSession();
              this.media = Object.create(this.session.getMediaSession());
              this.customData = Object.create(
                this.session.getMediaSession().media.customData
              );
              this.asset = this.customData.asset;
              this.updateDisplay();
              this.fetchMediaInterval();
              this.$bus.$emit("player-cast-ready");
              this.assetLoaded = true;
              resolve();
            },
            (error) => {
              this.assetLoaded = false;
              this.isLoadingMedia = false;
              if (!this.assetLoaded) {
                this.$bus.$emit("cast-removelisteners");
                this.session.endSession();
                this.$bus.$emit(
                  "show-toast",
                  "error",
                  `Error al cargar video en el dispositivo: ${this.friendlyName}`
                );
                this.$bus.$emit("cast:connected", false);
                this.$bus.$emit("player-cast-ready");
              }
              reject(error);
              console.error(
                "[CcastStore] There was an error when loading media",
                error
              );
            }
          );
        });
      });
    },
    createMediaInfo(playerSource, metadata) {
      return new Promise((resolve) => {
        let mediaInfo = new chrome.cast.media.MediaInfo("", "video/mp4");
        if (playerSource.status) {
          switch (playerSource.status) {
            case "PRESENTE":
              telecentro.tplay.core.epg.obtenerProgramacionHoy(
                playerSource.channel.id,
                false,
                (programs) => {
                  mediaInfo.metadata = metadata;
                  mediaInfo.contentId = playerSource.channel.id + "";
                  mediaInfo.title = playerSource.title;
                  mediaInfo.streamType = "LIVE";
                  mediaInfo.contentType = "videos/mp4";
                  mediaInfo.providerThumbUrl = playerSource.poster;
                  mediaInfo.duration = playerSource.duracionTotalSegundos;
                  mediaInfo.customData = {
                    asset: playerSource,
                    channelId: playerSource.channel.id,
                    providerThumbUrl: playerSource.poster,
                    channelName: playerSource.channel.name,
                    startTime: playerSource.startHourUnixTime,
                    endTime: playerSource.endHourUnixTime,
                    authToken:
                      telecentro.tplay.core.localStorageFake.getItem(
                        "tplayCasAuthToken"
                      ),
                    videoType: CAST.VIDEO_TYPE.LIVE,
                    programs: programs.filter((e) => {
                      return e.status !== "PASADO";
                    }),
                  };
                  (mediaInfo.customData.tracks = { audio: "es", text: "none" }),
                    resolve(mediaInfo);
                }
              );
              break;
            case "PASADO":
              // El receiver obtiene de otra forma los catchup y precisa del Catchup id event para obtener la fuente.
              telecentro.tplay.core.epg.obtenerIdEventoCatchup(
                playerSource.idAsset,
                (idCatchupEvent) => {
                  mediaInfo.metadata = metadata;
                  mediaInfo.contentId = idCatchupEvent + "";
                  mediaInfo.streamType = "BUFFERED";
                  mediaInfo.contentType = "videos/mp4";
                  mediaInfo.duration =
                    playerSource.endHourUnixTime -
                    playerSource.startHourUnixTime; //TODO: QUE VALOR TOMAR
                  mediaInfo.customData = {
                    subtitleLanguage: playerSource.sub,
                    audioLanguage: playerSource.audio,
                    subtitle: playerSource.title,
                    asset: playerSource,
                    providerThumbUrl: playerSource.channel.logo,
                    thumbnail: playerSource.poster,
                    thumbnailFormat: "landscape",
                    title: playerSource.title,
                    authToken:
                      telecentro.tplay.core.localStorageFake.getItem(
                        "tplayCasAuthToken"
                      ),
                    videoType: CAST.VIDEO_TYPE.CATCHUP,
                  };
                  (mediaInfo.customData.tracks = { audio: "es", text: "none" }),
                    resolve(mediaInfo);
                }
              );
              break;
            case "Recorded":
              mediaInfo.metadata = metadata;
              mediaInfo.contentId = playerSource.idRecord + "";
              mediaInfo.streamType = "BUFFERED";
              mediaInfo.contentType = "videos/mp4";
              mediaInfo.providerThumbUrl = playerSource.poster;
              mediaInfo.duration =
                playerSource.endHourUnixTime - playerSource.startHourUnixTime;
              mediaInfo.customData = {
                subtitleLanguage: playerSource.sub,
                audioLanguage: playerSource.audio,
                asset: playerSource,
                channelId: playerSource.channel.id,
                channelName: playerSource.channel.name,
                providerThumbUrl: playerSource.poster,
                authToken:
                  telecentro.tplay.core.localStorageFake.getItem(
                    "tplayCasAuthToken"
                  ),
                videoType: CAST.VIDEO_TYPE.NPVR,
              };
              (mediaInfo.customData.tracks = { audio: "es", text: "none" }),
                resolve(mediaInfo);
              break;
            default:
              break;
          }
        } else {
          mediaInfo.metadata = metadata;
          mediaInfo.contentId = playerSource.idAsset + "";
          mediaInfo.streamType = "BUFFERED";
          mediaInfo.contentType = "videos/mp4";
          mediaInfo.providerThumbUrl = playerSource.poster;
          mediaInfo.duration = playerSource.duration;
          mediaInfo.customData = {
            asset: playerSource,
            audioLanguage: playerSource.audio,
            subtitleLanguage: playerSource.sub,
            videoType: CAST.VIDEO_TYPE.VOD,
            id: playerSource.idAsset,
            authToken:
              telecentro.tplay.core.localStorageFake.getItem(
                "tplayCasAuthToken"
              ),
            duration: playerSource.duration,
            tracks: window.vip.player.getTracks(),
          };
          mediaInfo.customData.tracks = { audio: "es", text: "none" };
          if (playerSource.type === "CHAPTER") {
            //SERIE
            mediaInfo.customData.title = playerSource.title;
            let season = playerSource.n_temporada
              ? "S" + playerSource.seasonNumber
              : " ";
            let episode = playerSource.n_episodio
              ? "E" + playerSource.n_episodio
              : null;
            let episodeInfo = (season ? season : "") + (episode ? episode : "");
            mediaInfo.customData.subtitle =
              episodeInfo +
              (episodeInfo ? " - " : "") +
              (playerSource.title ? playerSource.title : "");
            mediaInfo.customData.thumbnailFormat = "landscape";
            mediaInfo.customData.thumbnail = playerSource.poster;
          } else {
            //MOVIE
            mediaInfo.customData.title = playerSource.title;
            mediaInfo.customData.subtitle = playerSource.genre;
            mediaInfo.customData.thumbndailFormat = "portrait";
            mediaInfo.customData.thumbnail = playerSource.thumbnail;
          }
          mediaInfo.customData.providerThumbUrl = playerSource.poster
            ? playerSource.poster
            : "";
          //Continuar viendo
          // mediaInfo.seek = window.vip.player.getInstance().stream.seekTime;
          resolve(mediaInfo);
        }
      });
    },
    createGenericMetadata(playerSource) {
      let ls = new chrome.cast.media.TvShowMediaMetadata();
      ls.title = playerSource.title;
      ls.subtitle = playerSource.synopsis;
      ls.images = [
        {
          url: playerSource.poster,
        },
      ];
      return ls;
    },
    setPosition(time, ms) {
      this.progress.value = time;
      this.lastPositionms = ms;
    },
    updateDisplay() {
      if (this.session && this.media) {
        let mediaTitle = "";
        let mediaEpisodeTitle = "";
        let mediaSubtitle = "";
        if (this.asset) {
          mediaTitle = this.asset.title;
          mediaEpisodeTitle = this.asset.synopsis;

          if (document.getElementsByClassName("video-title")[0])
            document.getElementsByClassName("video-title")[0].innerHTML =
              mediaTitle;
          // live information
          if (this.customData.videoType == chrome.cast.media.StreamType.LIVE) {
            this.liveSeekableRange = this.media.liveSeekableRange;
          }
        } else {
          // media_info view
          document.getElementById("media_title").innerHTML = "";
          document.getElementById("media_subtitle").innerHTML = "";
        }
      }
    },
    fetchMediaInterval() {
      if (this.intervals.media) clearInterval(this.intervals.media);
      this.intervals.media = setInterval(() => {
        this.progress.min =
          this.customData.videoType !== CAST.VIDEO_TYPE.VOD
            ? this.asset.startHourUnixTime
            : 0;
        this.progress.max =
          this.customData.videoType === CAST.VIDEO_TYPE.LIVE
            ? this.asset.endHourUnixTime
            : this.media.media.duration;
        const timeLapsed = new Date(
          Math.trunc(this.progress.value * 1000)
        ).getTime();
        if (timeLapsed > 0) {
          this.bus.$emit("remote:seek-time", timeLapsed);
        }
        this.checkCambioProgramacion();
        if (this.media.playerState) {
          this.isLoadingMedia = false;
        }
        if (this.media.playerState === "IDLE") {
          clearInterval(this.intervals.media);
        }
      }, 1000);
    },
    checkCambioProgramacion() {
      try{
        if (
          this.asset.type === "CHAPTER" ||
          this.asset.type === "SERIE" ||
          this.asset.type === "MOVIE" ||
          typeof this.session.getMediaSession().getEstimatedTime() ===
            "undefined" ||
          this.session.getMediaSession().getEstimatedTime() === null
        )
          return;
        if (this.customData.videoType === CAST.VIDEO_TYPE.LIVE) {
          for (let index = 0; index < this.customData.programs.length; index++) {
            if (
              this.session.getMediaSession().getEstimatedTime() >=
                this.customData.programs[index].startHourUnixTime &&
              this.session.getMediaSession().getEstimatedTime() <=
                this.customData.programs[index].endHourUnixTime
            ) {
              this.asset = this.customData.programs[index];
              this.updateDisplay();
            }
          }
        }
      }catch(e){
        return;
      }
    },
    isMediaLoaded() {
      return (
        this.localPlayer.src !== null &&
        this.localPlayer.src !== "" &&
        this.localPlayer.src !== undefined
      );
    },
    playPause() {
      if (this.playerState == PLAYER_STATE.IDLE) {
        return;
      }
      this.playerState = PLAYER_STATE.PLAYING;
      tplay.ccast.remotePlayerController.playOrPause();
    },
    seekMediaRemote(seekTime) {
      if (!cast.framework.CastContext.getInstance().getCurrentSession()) {
        console.info("no hago seek porque no estoy conectado");
        return;
      }
      tplay.ccast.remotePlayer.currentTime = parseInt(seekTime, 10) / 1000;
      tplay.ccast.remotePlayerController.seek();
    },
    getMediaDuration() {
      if (this.isLiveContent) {
        // Scenerios when live metadata is not provided.
        if (
          this.mediaInfo.metadata == undefined ||
          this.mediaInfo.metadata.sectionDuration == undefined ||
          this.mediaInfo.metadata.sectionStartTimeInMedia == undefined
        ) {
          return null;
        }
        return this.mediaInfo.metadata.sectionDuration;
      } else {
        return tplay.ccast.remotePlayer.duration;
      }
    },
    getCurrentMediaTime() {
      if (
        this.isLiveContent &&
        this.mediaInfo.metadata &&
        this.mediaInfo.metadata.sectionStartTimeInMedia
      ) {
        return (
          tplay.ccast.remotePlayer.currentTime -
          this.mediaInfo.metadata.sectionStartTimeInMedia
        );
      } else {
        return tplay.ccast.remotePlayer.currentTime;
      }
    },
    incrementMediaTime() {
      // First sync with the current player's time
      const session = cast.framework.CastContext.getInstance();
      if (
        session.getCurrentSession() &&
        session.getCurrentSession().getMediaSession()
      ) {
        tplay.ccast.estimatedRemoteMediaTime =
          cast.framework.CastContext.getInstance()
            .getCurrentSession()
            .getMediaSession()
            .getEstimatedTime();
      }

      this.currentMediaTime = this.getCurrentMediaTime();
      this.mediaDuration = this.getMediaDuration();

      // this.playerHandler.updateDurationDisplay(); revisar

      if (
        this.mediaDuration == null ||
        this.currentMediaTime < this.mediaDuration ||
        this.isLiveContent
      ) {
        // this.playerHandler.updateCurrentTimeDisplay(); revisar
      } else if (this.mediaDuration > 0) {
        this.endPlayback();
      }
    },
    stopProgressTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    startProgressTimer() {
      this.stopProgressTimer();
      // Start progress timer
      this.timer = setInterval(this.incrementMediaTime, TIMER_STEP);
    },
    endPlayback() {
      this.currentMediaTime = 0;
      this.stopProgressTimer();
      this.playerState = PLAYER_STATE.IDLE;
      this.updateDisplay();
    },
    onMediaLoadedLocally() {
      let castSession =
        cast.framework.CastContext.getInstance().getCurrentSession();
      if (!castSession) {
        return;
      }
      this.prepareToPlay();
    },
    prepareToPlay() {
      this.mediaDuration = this.getMediaDuration();
      // castPlayer.playerHandler.updateDurationDisplay();
      this.playerState = PLAYER_STATE.LOADED;

      // this.play();
      this.startProgressTimer();
    },
    muteOrUnmuted() {
      tplay.ccast.remotePlayerController.muteOrUnmute();
      this.playerState = PLAYER_STATE.IS_MUTED_CHANGED;
    },
    setVolume(vol) {
      cast.framework.CastContext.getInstance()
        .getCurrentSession()
        .setVolume(vol);
    },
    setTextTrack(track, type) {
      const MESSAGE_NAMESPACE = "urn:x-cast:com.connectsdk";
      const message = {
        action: type,
        params: track.name ? track.name : null,
      };
      cast.framework.CastContext.getInstance()
        .getCurrentSession()
        .sendMessage(MESSAGE_NAMESPACE, message),
        (r) => {
          console.info("succes", r);
        },
        (e) => {
          console.info("error", e);
        };
    },
  },
  watch: {
    title(val) {
      if (
        !val &&
        cast.framework.CastContext.getInstance().getCurrentSession() != null
      )
        return;
      if (!this.isCCast) {
        this.$bus.$emit("set-cast-title", "");
        return;
      }
      this.$bus.$emit("set-cast-title", val);
    },
    subtitle(val) {
      if (
        !val &&
        cast.framework.CastContext.getInstance().getCurrentSession() != null
      )
        return;
      if (!this.isCCast) {
        this.$bus.$emit("set-cast-subtitle", "");
        return;
      }
      this.$bus.$emit("set-cast-subtitle", val);
    },
  },
  beforeDestroy() {
    this.$bus.$off("cast-removeevents", () => {
      this.removeEvents();
    });
    this.$bus.$off("cast-setevents", () => {
      this.setEvents();
    });
    this.localPlayer.removeEventListener(
      "loadeddata",
      this.onMediaLoadedLocally
    );
    this.removeEvents();
  },
};
</script>
